<template>
<v-container fluid>
  <!-- item banner -->
  <v-row>
    <ItemBanner v-if="currentItem" @remove="deleteItem" @restart="restartItem" @complete="completeItem" @updateDate="updateItemDate" @addProgress="addNewProgress" @changeLabel="changeLabel" @removeLabel="removeLabel" :item="currentItem" />
  </v-row>
  <remove-item-dlg ref="removeItemDlg" class="elevation-5" style="position:absolute;z-index:10;top:100px;right:50px;"></remove-item-dlg>
  <!-- tabs -->
  <v-row class="py-2 px-4">
    <v-tabs slider-size="5" v-model="tab">
      <v-tabs-slider></v-tabs-slider>
      <v-tab @click="getTaskPlan"><span class="text-subtitle-1">计划</span></v-tab>
      <!-- <v-tab><span class="text-subtitle-1">里程碑</span></v-tab> -->
      <!-- <v-tab><span class="text-subtitle-1">看板</span></v-tab> -->
      <v-tab @click="getItemMilestone"><span class="text-subtitle-1">里程碑</span></v-tab>
      <v-tab @click="getItemKanban"><span class="text-subtitle-1">看板</span></v-tab>
      <v-tab @click="getItemDynamic"><span class="text-subtitle-1">动态</span></v-tab>
      <v-tab @click="getGanttChart"><span class="text-subtitle-1">甘特图</span></v-tab>
      <v-tab @click="getItemStatisticsClick"><span class="text-subtitle-1">统计</span></v-tab>
      <v-tab @click="getItemSchedule"><span class="text-subtitle-1">进度</span></v-tab>
      <v-tab><span class="text-subtitle-1">成员</span></v-tab>
      <!-- <v-tab><span class="text-subtitle-1">外部成员</span></v-tab> -->
      <v-tab><span class="text-subtitle-1">互动</span></v-tab>
      <v-tab><span class="text-subtitle-1">基本信息</span></v-tab>
      <!-- <v-tab><span class="text-subtitle-1">审批</span></v-tab> -->
      <v-tab><span class="text-subtitle-1">文件</span></v-tab>
    </v-tabs>
    <!-- tab items -->
    <v-tabs-items  v-model="tab">
      <!-- item plan -->
      <v-tab-item>
        <ItemPlan :item="currentItem" ref="ItemPlan" />
      </v-tab-item>
      <!-- item milestone -->
      <v-tab-item>
        <!-- <ItemMilestone :item="currentItem" @pick="openMSEditor" @selectTask="selectTask" /> -->
        <ItemMilestone :item="currentItem" @pick="openMSEditor" ref="ItemMilestone" />
      </v-tab-item>
      <!-- item Kanban -->
      <v-tab-item>
        <!-- <ItemKanban :item="currentItem" @selectTask="selectTask"/> -->
        <ItemKanban ref="ItemKanban" :item="currentItem"/>
      </v-tab-item>
      <!-- item dynamic -->
      <v-tab-item>
        <ItemDynamic ref="ItemDynamic" :item="currentItem" />
      </v-tab-item>
      <!-- gantt chart -->
      <v-tab-item>
        <GanttChart ref="GanttChart" :item="currentItem" />
      </v-tab-item>
      <!-- item statistics 统计-->
      <v-tab-item>
        <ItemStatistics ref="ItemStatistics" :item="currentItem"/>
      </v-tab-item>
      <!-- item schedule -->
      <v-tab-item>
        <ItemSchedule :item="currentItem" ref="ItemSchedule" @addProgress="addNewProgress" />
      </v-tab-item>
      <!-- item member -->
      <v-tab-item>
        <ItemMember :item="currentItem" />
      </v-tab-item>
      <!-- item external member -->
      <!-- <v-tab-item>
        <ItemExternalMember/>
      </v-tab-item> -->
      <!-- item interactive -->
      <v-tab-item>
        <div>
					<Operating v-if="!!currentItem" :op_obj="{op_parent:!!currentItem?currentItem.item_id:0, op_parent_type:3}"/>
				</div>
				<div>
					<Intercom :ic_obj="{ic_parent:!!currentItem?currentItem.item_id:0, ic_parent_type:3}" />
				</div>
      </v-tab-item>
      <!-- item basic information -->
      <v-tab-item>
        <ItemBasicInfo :item="currentItem" />
      </v-tab-item>
      <!-- item approve -->
      <!-- <v-tab-item>
        <ItemApprove/>
      </v-tab-item> -->
      <!-- item file -->
      <v-tab-item>
        <ItemFile :item="currentItem"/>
      </v-tab-item>
    </v-tabs-items>
  </v-row>
  <RightTaskEditor ref="rightTaskEditor"/>
  <milestone-editor ref="msEditor"></milestone-editor>
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import { Routes } from '@/constants/routes'
import RightTaskEditor from '@/components/common/taskEdit/TaskEdit.vue'
export default {
  components: {
    ItemBanner: () => import('@/components/itemDetail/ItemBanner.vue'),
    ItemPlan: () => import('@/components/itemDetail/ItemPlan.vue'),
    ItemMilestone: () => import('@/components/itemDetail/ItemMilestone.vue'),
    ItemKanban: () => import('@/components/itemDetail/ItemKanban.vue'),
    ItemDynamic: () => import('@/components/itemDetail/ItemDynamic.vue'),
    GanttChart: () => import('@/components/itemDetail/GanttChart.vue'),
    ItemStatistics: () => import('@/components/itemDetail/ItemStatistics.vue'),
    ItemSchedule: () => import('@/components/itemDetail/ItemSchedule.vue'),
    ItemMember: () => import('@/components/itemDetail/ItemMember.vue'),
    ItemExternalMember: () => import('@/components/itemDetail/ItemExternalMember.vue'),

		Operating: () => import('@/components/common/operating/Operating.vue'),
		Intercom:() =>import("@/components/common/Intercom/Intercom.vue"),

    ItemBasicInfo: () => import('@/components/itemDetail/ItemBasicInfo.vue'),
    ItemApprove: () => import('@/components/itemDetail/ItemApprove.vue'),
    ItemFile: () => import('@/components/itemDetail/ItemFile.vue'),
    RightTaskEditor,
    MilestoneEditor: () => import('@/components/itemDetail/MilestoneEditor.vue'),
    RemoveItemDlg: () => import('@/components/itemDetail/RemoveItemDlg.vue')
  },
  computed: {
    ...mapGetters('item', ['items']),
    currentItem: function () {
      return this.items[0]
    }
  },
  data: () => ({
    itemName: null,
    tab: null
  }),
  methods: {
    ...mapActions('item', ['getItems', 'updateItemByField', 'removeItem']),
    ...mapActions('user', ['getUsers']),
    ...mapActions('dashboard', ['getItemStatistics']),
    ...mapActions('progress', ['addProgressCallback']),

    getItemMilestone(){
			if(!!this.$refs.ItemMilestone){
				this.$refs.ItemMilestone.getMilestones();
			}
		},
    getItemKanban(){
			if(!!this.$refs.ItemKanban){
				this.$refs.ItemKanban.getKanbans();
			}
		},

    //动态
		getItemDynamic(){
			if(!!this.$refs.ItemDynamic){
				this.$refs.ItemDynamic.updateList();
			}
		},
    //进度
		getItemSchedule(){
			if(!!this.$refs.ItemSchedule){
				this.$refs.ItemSchedule.updatePage(); 
			}
		},
    //甘特图
		getGanttChart(){
			// if(!!this.$refs.GanttChart){
			// 	this.$refs.GanttChart.updateData(); 
			// }
		},
    //统计
    getItemStatisticsClick(){
			//if(!!this.$refs.ItemStatistics){
			//	this.$refs.ItemStatistics.updateList();
			//}
      //this.$refs.ItemStatistics.statistics();
    // this.getItemStatistics({"req_item_id": this.currentItem.item_id});
		},
    //计划
		getTaskPlan(){
			this.$refs.ItemPlan.getTasksByFilter();
		},

    changeLabel(e) {
      let itemTag = this.currentItem.item_tag.split(',')
      itemTag.push(e)
      this.updateItemByField({
        item_id: this.currentItem.item_id,
        item_tag: itemTag.join()
      })
    },
    removeLabel(id) {
      let itemTag = this.currentItem.item_tag.split(',')
      const index = itemTag.findIndex(tag => tag == id)
      itemTag.splice(index, 1)
      this.updateItemByField({
        item_id: this.currentItem.item_id,
        item_tag: itemTag.join()
      })
    },
    addNewProgress(e) {
      this.addProgressCallback(e).then(() => {
        this.getItems({
          item_id: this.$route.query.id
        })
				this.getItemSchedule();
      })
    },
    updateItemDate(e) {
      this.updateItemByField(e)
    },
    completeItem() {
      this.updateItemByField({
        item_id: this.currentItem.item_id,
        item_status: 1
      })
    },
    restartItem() {
      this.updateItemByField({
        item_id: this.currentItem.item_id,
        item_status: 0
      })
    },
    async deleteItem() {
      const res = await this.$refs.removeItemDlg.open();
      if(!!res) {
        this.removeItem({
          item_id: this.currentItem.item_id,
          item_del_mode: res.mode
        });
        this.$router.push({path: Routes.EXE_PROJECT_PAGE});
      }
    },
    selectTask(e) {
      this.$refs.rightTaskEditor.openTaskEditor({task_id: e})
    },
    openMSEditor(e) {
      this.$refs.msEditor.close();
      this.$refs.msEditor.open(e);
    }
  },
  mounted() {
    this.getItems({
      item_id: this.$route.query.id
    })
    this.getUsers()
    // this.getItemStatistics({"req_item_id": 500539352});
     this.getItemStatistics({"req_item_id": this.$route.query.id});
  }
}
</script>

<style scoped>
.v-tabs-items {
  width: 100%;
}
</style>
